import '../../css/CollectionItem.scss';
// import { ReactComponent as RarityScore } from 'src/icons/rarity.svg';
// import { ReactComponent as Time } from 'src/icons/time.svg';
// import { ReactComponent as Value } from 'src/icons/value.svg';

const contract_to_collection = {'0x58bab97836e4932df9f2c254a3fa8c6c403c9243': 'numbers',
                                '0xc8b3482975257a9344abd63d6ea9d21b0a996c65': 'numbers'}

const CollectionItem = ({  masterpiece, imageUrl }) => {
  
  //imageUrl = 'https://testsite.104zero.com'
  //console.log(`${imageUrl}/${contract_to_collection[masterpiece.contract.address]}/${ Number(masterpiece.id.tokenId)}`)
  return (
    <div className="collection-item">
      <div className="title">
        <span className="name">
          RNUM <strong>{`#${ Number(masterpiece.id.tokenId)}`}</strong>
        </span>
        <span className="type">{ contract_to_collection[masterpiece.contract.address]}</span>
      </div>
      <div className="content">
        {/* <span>
          <RarityScore />
          Rarity Score:{' '}
          <strong>{Number( masterpiece.rarity.rarityScore).toFixed(1)}</strong>
        </span>
        <span>
          <Time />
          Early Mint Multiplier:{' '}
          <strong>{Number( masterpiece.rarity.earlyMintMultiplier).toFixed(2)}X</strong>
        </span>
        <span>
          <Value />
          Roughage Value: <strong>{ masterpiece.rarity.rougageValue}</strong>
        </span> */}
        <a href={`${imageUrl}/${contract_to_collection[masterpiece.contract.address]}/${ Number(masterpiece.id.tokenId)}`} target="_blank" rel="noreferrer">
          <img src={ `${imageUrl}/${contract_to_collection[masterpiece.contract.address]}/${ Number(masterpiece.id.tokenId)}`} alt=" masterpiece" />
         
        </a>
      </div>
    </div>
  );
};

export default CollectionItem;
