/*!
*
*/
import React, { useContext } from "react";
import { Web3Context } from "../../web3";
import { Link } from "react-router-dom";


// import {
//   showWeb3WalletModal,
//   getCurrentWalletAddress,
//   disconnectWallet,
// } from '../../utils/wallet'

//import './walletButton.css';

// reactstrap components
import {
  Button,
  Collapse, 
  // DropdownToggle,
  // DropdownMenu,
  // DropdownItem,
  // UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";


function shortAcct(account)
{
  let btnTxt = 'Connect Wallet';
  if(account != null){
    btnTxt =
    `Connected: ` +
    String(account).substring(0, 4) +
    '...' +
    String(account).substring(39);
    
    // walletAction = logout;
  } 

  return(btnTxt);
}


export default function MainNavbar() {

  const { account, connectWeb3, logout, connected } = useContext(Web3Context);
 
  //let walletAction = connectWeb3;



  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const [collapseOut, setCollapseOut] = React.useState("");
  const [color, setColor] = React.useState("navbar-transparent");

  //const [connectBtnTxt, setConnectBtnTxt] = React.useState(shortAcct(account))
  const [myGalleryLink, setMyGalleryLink] = React.useState('')
 

  console.log(`account: ${connected}, eval: ${connected}`)
  //const [connectedStatus, setConnectedStatus] = React.useState(connected)

  React.useEffect(() => {
    window.addEventListener("scroll", changeColor);
    
    const doWalletStuff = () => {
      
    //   //const acct = await getCurrentWalletAddress()

     if (connected) {

       // const btnTxt = shortAcct(account);
        //setConnectBtnTxt(btnTxt)
        //setConnectedStatus(true)
        setMyGalleryLink(  
          <NavLink
          data-placement="bottom"
          href={`/privategallery/${account}`}
          rel="noopener noreferrer"
          //target="_blank"
          title="Your private gallery"
          >
          <i className="tim-icons icon-image-02" />
          <p className="d-lg-inline d-xl-inline">Gallery</p>
          </NavLink>                
        )
     }
      
    }
    doWalletStuff()


    return function cleanup() {
      window.removeEventListener("scroll", changeColor);
    };


  },[connected, account]);  ///added account here....

  // const connectShowWeb3WalletModal = () => {
  //   if (connectedStatus) {
  //     disconnectWallet().then(() => {
  //       window.location.reload()
  //     })
  //     //trackEvent('disconnect_wallet')
  //   } else {
  //     showWeb3WalletModal().then(() => {
  //       window.location.reload()
  //     })
  //     //trackEvent('connect_wallet')
  //   }
  // }

  const changeColor = () => {
    if (
      document.documentElement.scrollTop > 99 ||
      document.body.scrollTop > 99
    ) {
      setColor("bg-info");
    } else if (
      document.documentElement.scrollTop < 100 ||
      document.body.scrollTop < 100
    ) {
      setColor("navbar-transparent");
    }
  };
  const toggleCollapse = () => {
    document.documentElement.classList.toggle("nav-open");
    setCollapseOpen(!collapseOpen);
  };
  const onCollapseExiting = () => {
    setCollapseOut("collapsing-out");
  };
  const onCollapseExited = () => {
    setCollapseOut("");
  };
  // const scrollToDownload = () => {
  //   document
  //     .getElementById("download-section")
  //     .scrollIntoView({ behavior: "smooth" });
  // };

  const scrollToStory = () => {
    document
      .getElementById("story-section")
      .scrollIntoView({ behavior: "smooth" });
  };  

  const scrollToCollections = () => {
    document
      .getElementById("collection-section")
      .scrollIntoView({ behavior: "smooth" });
  }; 

  const scrollToCharity = () => {
    document
      .getElementById("charity-section")
      .scrollIntoView({ behavior: "smooth" });
  }; 

  return (
    <Navbar className={"fixed-top " + color} color-on-scroll="100" expand="lg">
      <Container>
        <div className="navbar-translate">
          <NavbarBrand to="/" tag={Link} id="navbar-brand">
            <span>RAMbrandt </span>
            ARTificial Intelligence
          </NavbarBrand>
          <UncontrolledTooltip placement="bottom" target="navbar-brand">
            Ai meets art
          </UncontrolledTooltip>
          <button
            aria-expanded={collapseOpen}
            className="navbar-toggler navbar-toggler"
            onClick={toggleCollapse}
          >
            <span className="navbar-toggler-bar bar1" />
            <span className="navbar-toggler-bar bar2" />
            <span className="navbar-toggler-bar bar3" />
          </button>
        </div>
        <Collapse
          className={"justify-content-end " + collapseOut}
          navbar
          isOpen={collapseOpen}
          onExiting={onCollapseExiting}
          onExited={onCollapseExited}
        >
          <div className="navbar-collapse-header">
            <Row>
              <Col className="collapse-brand" xs="6">
                <a href="/#" onClick={(e) => e.preventDefault()}>
                  RAMbrandt•Ai
                </a>
              </Col>
              <Col className="collapse-close text-right" xs="6">
                <button
                  aria-expanded={collapseOpen}
                  className="navbar-toggler"
                  onClick={toggleCollapse}
                > 
                  <i className="tim-icons icon-simple-remove" />
                </button>
              </Col>
            </Row>
          </div>
          <Nav navbar>  {/*this had: navbar> to follow but i think error*/}

            <NavItem className="p-0">
                <NavLink
                  data-placement="bottom"
                  //href="#Links"
                  onClick={scrollToStory}
                  rel="noopener noreferrer"
                  //target="_blank"
                  title="RAMbrandt's Story"
                  
                >
                  <i className="tim-icons icon-laptop" />
                  <p className="d-lg-inline d-xl-inline">Story</p>
                </NavLink>
              </NavItem>
              <NavItem className="p-0">
                <NavLink
                  data-placement="bottom"
                  //href="https://www.facebook.com/CreativeTim"
                  rel="noopener noreferrer"
                  onClick={scrollToCharity}
                  //target="_blank"
                  title="Art for a good cause"
                >
                  <i className="tim-icons icon-gift-2" />
                  <p className="d-lg-inline d-xl-inline">Charities</p>
                </NavLink>
              </NavItem>

              <NavItem className="p-0">
                <NavLink
                  data-placement="bottom"
                  //href="https://www.facebook.com/CreativeTim"
                  rel="noopener noreferrer"
                  //target="_blank"
                  onClick={scrollToCollections}
                  title="RAMbrandt's Collections"
                >
                  <i className="tim-icons icon-book-bookmark" />
                  <p className="d-lg-inline d-xl-inline">Collections</p>
                </NavLink>
              </NavItem>

              <NavItem className="p-0">
                  {myGalleryLink}
              </NavItem>

              <NavItem>
                <Button
                  className={`btn-default ${connected && 'wallet-connected'} btn-nav`}
                  color={connected ? "success" : "primary"}
                  onClick={connected ? logout : connectWeb3}
                  
                >
                  
                  <i className="tim-icons icon-wallet-43" /> 
                  <a>{connected ? shortAcct(account) : "Connect Wallet"}</a>
                </Button>
              </NavItem>
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
}

/*
=========================================================
* Coomponent derived from
* BLK Design System React - v1.2.0
=========================================================
* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/main/LICENSE.md)
=========================================================
*/