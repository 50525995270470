import React from "react";
import ReactDOM from "react-dom";
import "./index.css";

// import "./css/bootstrap/bootstrap.css"
import 'bootstrap/dist/css/bootstrap.min.css';

import "./css/nucleo-icons.css"

import "./css/myrambrandt.css";

//import App from "./App.js";
import reportWebVitals from "./reportWebVitals";

import { Web3Provider } from "./web3";

// import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";


import Index from "./views/Index.js"
import PrivateCollection from "./views/PrivateCollection";

import PurchaseLoading from "./views/PurchaseLoading.js";
import PurchasePage from "./views/PurchasePage.js";
import TxnFail from "./views/TxnFail.js";
// const PurchaseLoading = lazy(() => import("./views/PurchaseLoading.js"));
// const PurchasePage = lazy(() => import('./views/PurchasePage.js'))
// const TxnFail = lazy(() => import('./views/TxnFail.js'))

ReactDOM.render(
  //<React.StrictMode>
    <BrowserRouter>
      
        <Web3Provider>
          {/*Moved switch from above web3provider*/}
          <Switch>
          {/* <Route path="/" render={(props) => <Index {...props} />} /> */}
          
            <Route path="/loading/:txnId/id/:uuid" render={(props) => < PurchaseLoading {...props} />} />
            <Route path='/txn-error/:txnId' render={(props) => < TxnFail {...props} />} />
            <Route path='/numbers/:tokenId/txn/:txnId' render={(props) => < PurchasePage {...props} />} />
            <Route path='/privategallery/:address' render={(props) => < PrivateCollection {...props} />} />
            <Route path="/" render={(props) => <Index {...props} />} />
          </Switch>
        </Web3Provider>
      
    </BrowserRouter>, 
 // </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
