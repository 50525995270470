/*!

*/
import React from "react";

//import Web3Modal from "web3modal";
//import web3 from "web3"

// core components
import MainNavbar from "../components/Navbars/MainNavbar.js";
import PageHeader from "../components/PageHeader/PageHeader.js";
// import Footer from "../components/Footer/Footer.js";

// sections for this page/view
// import Basics from "views/IndexSections/Basics.js";
// import Navbars from "views/IndexSections/Navbars.js";
// import Tabs from "views/IndexSections/Tabs.js";
// import Pagination from "views/IndexSections/Pagination.js";
// import Notifications from "views/IndexSections/Notifications.js";
// import Typography from "views/IndexSections/Typography.js";
// import JavaScript from "views/IndexSections/JavaScript.js";
// import NucleoIcons from "views/IndexSections/NucleoIcons.js";
// import Signup from "views/IndexSections/Signup.js";
// import Examples from "views/IndexSections/Examples.js";
// import Download from "views/IndexSections/Download.js";

export default function Index() {
  //const { account, connectWeb3, logout } = useContext(Web3Context);

  React.useEffect(() => { 
    document.body.classList.toggle("index-page");
    // Specify how to clean up after this effect:
    return function cleanup() {
      document.body.classList.toggle("index-page");
    };
  },[]);

  // const providerOptions = { 
  //   /* See Provider Options Section */
  // };
  //const protocol = process.env.HTTPS === 'true' ? 'https' : 'http';
  //console.log(protocol)

  // const web3Modal = new Web3Modal({
  //   network: "ropsten", // optional
  //   cacheProvider: true, // optional
  //   providerOptions // required
  // });

  //const provider = async () => await web3Modal.connect();

  return (
    <>
      
      <MainNavbar />
      <div className="wrapper">
        <PageHeader />

        <div className="main">
          {/* <Basics />
          <Navbars />
          <Tabs />
          <Pagination />
          <Notifications />
          <Typography />
          <JavaScript />
          <NucleoIcons />
          <Signup />
          <Examples />
          <Download /> */}
        </div>
        {/*<Footer />*/}
        
      </div>
    </>
  );
}

// =========================================================
// Numerous components utilized from Creative Tim,
// Check out his assets:
// * Product Page: https://www.creative-tim.com/product/blk-design-system-react
// * Copyright 2020 Creative Tim (https://www.creative-tim.com)
// * Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/main/LICENSE.md)
// =========================================================
