// import React, { useEffect, useState, useContext } from 'react';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import MintButton from '../components/Mint-Button/MintButton.js';
import CollectionItem from '../components/Collection/CollectionItem.js';
import PurchaseLayout from '../components/Layout/PurchaseLayout.js'
// import { Web3Context } from "../web3";
// import { getCurrentWalletAddress } from 'src/utils/wallet';
// import { gtmInitialize } from 'src/utils/gtm';

import '../css/PrivateCollection.scss';



// export default function PrivateCollection()  {
const PrivateCollection = () => {

  // const { account } = useContext(Web3Context);
  const { address } = useParams();
  const account = address;
  console.log(account)

  const [myCollectionNotFound, setMyCollectionNotFound] = useState(
    'Looking for your masterpieces...'
  );
  const [myCollection, setMyCollection] = useState();
  const apiUrl = process.env.REACT_APP_API_ADDRESS;
  const osUrl = 'https://testsite.104zero.com'
  // const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS;
  // const env = process.env.REACT_APP_APPLICATION_ENV; 
  // env === 'prod'
    //   ? `https://opensea.io/assets/${contractAddress}`
    //   : `https://testnets.opensea.io/assets/${contractAddress}`;

  useEffect(() => {
    const fetchAPIData = async () => {
      const walletAddr = account;//await getCurrentWalletAddress();
      console.log(`fetching: ${apiUrl}/user/${walletAddr}`)   
      // fetch(`${apiUrl}/user/${walletAddr}`)
      fetch(`${apiUrl}/user/${walletAddr}`)
        .then((res) => {
          if (res.status === 404) {
            setMyCollectionNotFound('No masterpieces found');
          } else {
            res.json().then((j) => {
             // console.log(JSON.stringify(j))
              if (j) {
                setMyCollection(j);
                if( (Array.isArray(myCollection) && myCollection.length === 0) || !myCollection){
                  console.log('setting not found collection')
                  setMyCollectionNotFound('No Masterpieces Found...Mint Today!')
                }
              } else {
                setMyCollectionNotFound('Empty response from server');
              }
            });
          }
        })
        .catch((err) => {
          console.error({ err });
          setMyCollectionNotFound('Error occurred');
        });
    };

   // gtmInitialize();
    (async () => {
      await fetchAPIData();
    })();
  }, [account, apiUrl]);  //added account, apiUrl

  if (Array.isArray(myCollection) && myCollection.length) {
    return (
      <PurchaseLayout className="mycollection" title="Rambrandt - Private Gallery">
        <>
          <div className="nav-header">
            <div className="container">
              <h2>My Masterpieces</h2>
              <MintButton />
            </div>
          </div>
          <div className="masterpiece-wrapper">
            <div className="container">
              <div className="masterpiece-list">
                {myCollection.map( (masterpiece, index) => (
                  <CollectionItem key={index} masterpiece={masterpiece} imageUrl={osUrl} />
                ))}
              </div>
            </div>
          </div>
        </>
      </PurchaseLayout>
    );
  } else {
    
    return (
      <PurchaseLayout className="mycollection" title="Rambrandt - Private Collection">
        <>
          <div className="nav-header">
            <div className="container">
              <h2>My Masterpieces</h2>
              <MintButton />
            </div>
          </div>
          <div className="masterpiece-wrapper">
            <div className="container">
              <h3>{myCollectionNotFound}</h3>
            </div>
          </div>
        </>
      </PurchaseLayout>
    );
  }
};

export default PrivateCollection;
