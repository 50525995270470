import React, { useContext } from 'react';
import { Web3Context } from "../../web3";
import './MintButton.css'
//import { useNavigate } from 'react-router-dom'; 
import {v4 as uuidv4} from 'uuid';

import {
    Button,
    Container,
    Row,
    Col,
    // UncontrolledTooltip,
  } from "reactstrap";



// import * as fs from 'fs';
// const path = require('path');

//let currToken = 1;//fs.readFileSync(path.resolve(__dirname, 'tokenCount.json'));

const URI_STORAGE_ADDRESS = process.env.REACT_APP_URI_STORAGE_ADDRESS;

// class MintButton extends React.Component{
export default function MintButton() {
    // const { account, connectWeb3, connected, mintNFT } = useContext(Web3Context);
    const { connected, mintNFT } = useContext(Web3Context);
    //const history = useHistory();
    //render () {
        
        
        const mintClick = async () =>{
            console.log("mint clicked")
            // if(!connected){
            //     console.log('here')
            //     await connectWeb3();
            //     await mintNFT();
                
            // } else{
            //     await mintNFT();
            // }

            try{
               // console.log(`minting token: ${currToken}`)
                const new_uuid = uuidv4().replaceAll('-','');
                await mintNFT(`${URI_STORAGE_ADDRESS}${new_uuid}`, new_uuid

                    // (err, txnId) => {
                    //     if (err) {
                    //         console.log('error in awaiting mintNFT')
                    //         console.error(err);
                    //     }
                    //     // if (txnId) {
                    //     //   history.push(`/loading/${txnId}`);
                    //     // }
                    // }

                );
               // currToken += 1;
             //   fs.writeFileSync(path.resolve(__dirname, 'tokenCount.json'), currToken);
            }catch (err){
                console.log('mintNFT error:')
                console.log(err)
            }
            



        }

        return (

            <Container fluid className="mint-container">
                <Row>
                    <div>
                        <h5>Current collection: Numbers</h5>                        
                    </div>
                </Row>
                <Row>
                    <Col>
                            <span>Price: </span>
                            <span>0.01 ETH</span>
                            <div>{connected ? "connected" :"!connected"}</div>
                       
                    </Col>
                    <Col>
                        <Button color="primary" className ="btn"
                            onClick = {mintClick} >Mint Now!</Button>
                    </Col>
                </Row>
            </Container>

        );
   // }

}

// export default MintButton;