import { createContext, useCallback, useReducer, useEffect } from "react";
import { useHistory } from 'react-router-dom'; 

import Web3Modal from "web3modal";
// import { ethers } from "ethers";

import Web3 from 'web3';

import { Web3Reducer } from "./reducer";
import WalletConnect from "@walletconnect/web3-provider";
//import WalletConnect from "@walletconnect/node";
//import WalletConnectProvider from '@walletconnect/react-native-dapp';
// import WalletLink from "walletlink";
import CoinbaseWalletSDK from '@coinbase/wallet-sdk';

const initialState = { 
  loading: false,
  account: null,
  provider: null,
  connected: false,
  txnReceipt: null
};

const contract = require("./TestNFT104rink.json")
const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS;
//const tokenURI = "https://gateway.pinata.cloud/ipfs/QmVAyEPVXtaxBh1C1p2dqh3ND4ub3fJr8s4gQCi6F6F2m7";


const providerOptions = {
  walletconnect: { 
    package: WalletConnect,
    options: {
      infuraId: "e0b1e519ccfd495fb26ce55af1d5b928"
    }
  },

  //  walletlink: {
  coinbasewallet: {
    package: CoinbaseWalletSDK, //WalletLink, // Required
    options: {
      appName: "My Awesome App", // Required
      infuraId: "e0b1e519ccfd495fb26ce55af1d5b928", // Required unless you provide a JSON RPC url; see `rpc` below
      // rpc: "https://ropsten.infura.io/v3/e0b1e519ccfd495fb26ce55af1d5b928", // Optional if `infuraId` is provided; otherwise it's required
      rpc: "https://rinkeby.infura.io/v3/e0b1e519ccfd495fb26ce55af1d5b928",
      // chainId: 3, // Optional. It defaults to 1 if not provided.  3 is ropsten
      chainId: 4,
///TODO: CHANGE THE CHAIN ID BACK TO 3
      appLogoUrl: null, // Optional. Application logo image URL. favicon is used if unspecified
      darkMode: false // Optional. Use dark theme, defaults to false
    }
  },

  binancechainwallet: {
    package: true
  }

}

const web3Modal = new Web3Modal({
 // network: "mainnet", // optional
  //network: "ropsten", // optional
  network: "rinkeby",
  cacheProvider: true, // optional
  providerOptions: providerOptions,
});

export const Web3Context = createContext(initialState);

export const Web3Provider = ({ children }) => {
  const [state, dispatch] = useReducer(Web3Reducer, initialState);

  const navigate = useHistory();

  const setAccount = (account) => {
    dispatch({
      type: "SET_ACCOUNT",
      payload: account,
    });
  };

  const setProvider = (provider) => {
    dispatch({
      type: "SET_PROVIDER",
      payload: provider,
    });
    
  };

  const logout = () => {
    setAccount(null);
    setProvider(null);
    try {
      web3Modal.clearCachedProvider();
      if(state.provider.wc){
        console.log('provider has wc')
        state.provider.wc.killSession();
        console.log('killed')

      }else{
        console.log('provider has no wc') 
        
      }
    }catch(err){
      console.log('Unable to Clear Provider: ' +err);
    }
    localStorage.setItem("defaultWallet", null);
    //my add:
   // window.location.reload();   //this makes the "gallery" link go away.
  };

  // const setReceipt = (receipt) => {
  //   dispatch({
  //     type: "SET_RECEIPT",
  //     payload: receipt,
  //   });
  // };

  // const checkNet = async (isMint) => {

  //   let provider;
  //   const REQ_NET = "0x3";

  //   if(!state.provider){
  //     provider = await web3Modal.connect();
  //   } else{
  //     provider = state.provider.provider
  //   }

  //   try{
  //       const currentNet = await provider.request({
  //       method: 'eth_chainId',
  //     });
  //     console.log(`currentNet: ${currentNet}`)
    
  //     if(currentNet != REQ_NET){
  //         console.log('here')
  //         await provider.request({
  //           method: 'wallet_switchEthereumChain',
  //           params: [{ chainId: REQ_NET }],
  //         })
  //         console.log('done change')
  //         console.log(`ismint: ${isMint}`)
  //         if(isMint){
            
  //           console.log('requesting mint')
            
  //         }
  //     }
  //   }catch(e){
  //     console.log("unable to detect/change network")
  //   }
  //   console.log('running mint now')
  //   mintNFT();
  // }
  // const getCurrentWalletAddress = async() => {
  //   try {
  //     // if (web3Modal.cachedProvider) {
  //     //   provider = await web3Modal.connect();
  //     // } else {
  //     //   return null;
  //     // }
  //     let provider;
  //     if(state.provider){
  //       provider = state.provider;
  //     } else{
  //       provider = await web3Modal.connect();
  //     }
  //     // Get a Web3 instance for the wallet
  //     const web3 = new Web3(provider);
  //     const accounts = await web3.eth.getAccounts();
  //     if (accounts && accounts.length > 0) {
  //       //selectedAccount = accounts[0];
  //       return accounts[0];
  //     } else {
  //       return null;
  //     }
  //   } catch (e) {
  //     console.error('Could not getCurrentWalletAddress', e);
  //     return null;
  //   }
  // }

  const mintNFT = async (tokenURI, uuid) => {

    if(!tokenURI){
      tokenURI = "https://gateway.pinata.cloud/ipfs/QmVAyEPVXtaxBh1C1p2dqh3ND4ub3fJr8s4gQCi6F6F2m7";
    }

    let provider;
    let account;
    const REQ_NET = "0x4";  //0x3 ropsten, 0x4 rinkeby, 0x1 eth mainnet
    
    

    
    //const temp = Web3.givenProvider
    if(!state.provider){
      provider = await web3Modal.connect();
      
      account = await getCurrentWalletAddress(provider);
      //console.log(provider);
      console.log(`account: ${account}`);
      setProvider(provider);
    } else{
      provider = state.provider;//.provider;
      
    }
    //provider = provider.makeWeb3Provider("https://ropsten.infura.io/v3/e0b1e519ccfd495fb26ce55af1d5b928", 3)
    console.log("new provider")
    console.log(provider);
    const web3 = new Web3(provider);

    try{

      const currentNet = await provider.request({
        method: 'eth_chainId',
      });
      console.log(`currentNet: ${currentNet}`)
    
      if(currentNet !== REQ_NET){
          console.log('here await swap')
          console.log(provider)
          await provider.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: REQ_NET }],
          })
          console.log('done')
      }
    }catch(e){
      console.log("unable to detect/change network")
    }

    if(state.account){
      account = state.account;
      console.log(`state.account: ${account}`)
    }
    


    // const web3 = new Web3(provider);
    
    //console.log("network");
    // console.log(state.account)
    // console.log('try2')
    // console.log(Web3.givenProvider)
    // console.log('state provider')
    // console.log(state.provider._network);
    // console.log('end')
    // console.log(state.provider)
    //web3.eth.getChainId().then(console.log);
    // const balance = await web3.eth.getBalance(String(state.account));
    // const balance = await state.provider.getBalance(state.account);
    // console.log(balance)
    // console.log(`provider direct: ${balance}`)
    //
    
    const NFT = new web3.eth.Contract(contract.abi, contractAddress);
    // const temp2 = await web3.eth.getChainId();
    // console.log(`temp2: ${temp2}`)
    // if(!state.account){
    //   await setTimeout(() =>{} , 1000);
    // }
    
    if(account){  //state.account
      const tx = {
        'from': account,
        'to': contractAddress,
        //'nonce': nonce,
        'gas': 500000,
        'value': 10000000000000000,
        'data': NFT.methods.mintToken(account, tokenURI).encodeABI()
      };

      try{
        web3.eth.sendTransaction(tx,// cb);           
          
          function (err, hash) {
          if (!err) {
              console.log(
              "The hash of your transaction is: ",
              hash,
              "\nCheck Alchemy's Mempool to view the status of your transaction!"
              )
              

              navigate.push(`/loading/${hash}/id/${uuid}`);

          } else {
              console.log(
              "Something went wrong when submitting your transaction:",
              err
              )
          }
          });
      } catch(err){
          console.log(`error in mint: ${err}`)
      }

    } else{
      console.log('No Account for minting');
    }

  
  }

  const getTxnReceipt = async (txnId) =>{
    //console.log('receipt!')
    //console.log(txnId)

    
    if(state.provider){
      const provider = state.provider;
      const web3 = new Web3(provider);

      
    //  // web3 = Web3(Web3.HTTPProvider('https://bsc-dataseed.binance.org/'))
    //   const web3 = Web3("https://cloudflare-eth.com");
      // await web3.eth.getTransactionReceipt(txnId, (err, receipt) =>{
      //   if(err){
      //     return
      //   } else {
      //     console.log(`receipt is${receipt} in web3 src`)
      //     return receipt;
      //   }
      // });
      const receipt = await web3.eth.getTransactionReceipt(txnId.toString());
      //const receipt = await web3.eth.getTransactionReceipt('0xbb2c1a0cc3157b63f402e53fa199982529e58d713d697d9850ebc0d72fd8acc0');
      console.log(`txn: ${txnId}`)
      console.log(`receipt: ${receipt}`)
      console.log('yeah its done')

      // if( receipt && receipt.logs[0] && receipt.logs[0].topics){
      //   const NFT = new web3.eth.Contract(contract.abi, contractAddress);
      //   const tokenId = parseInt(receipt.logs[0].topics[3]) ;
      //   const tokenUri = `https://localhost:3000/NFTs/uri/${tokenId}.json`
      //   console.log('trying send with account: ');
      //   console.log(state.account);
      //   let account;
      //     if(!state.provider){
      //       provider = await web3Modal.connect();
            
      //       account = await getCurrentWalletAddress(provider);
      //       //console.log(provider);
      //       console.log(`account: ${account}`);
      //     } else{
      //       account = await getCurrentWalletAddress(provider);
      //     }    
        
        
      //   const tx = {
      //     'from': account,
      //     'data': NFT.methods.setTokenURI(tokenId, tokenUri).encodeABI()
      //   };      
        
      //   web3.eth.sendTransaction(tx, (err, receipt) =>{
      //       if(err){
      //         console.log('new URI failed')
      //       } else {
      //         console.log(`new URI Set`);
              
      //       }
      //     }
      //     );
      // }
      return receipt;
      
      
    }else{
      console.log('no provider in getTxnReceipt')
      const web3 = new Web3("https://cloudflare-eth.com");
      const receipt = await web3.eth.getTransactionReceipt(txnId.toString())
      //const receipt = await web3.eth.getTransactionReceipt('0xc8254fc5ebcd8e1ccb417392deb1715bfc30ee4db67a5b30f556b64299ef5ded')
      return receipt;     
    }

  }


  //const connected  = state.account != null ? true : false
  

  const connectWeb3 = useCallback(async () => {
    //my add:
    //await web3Modal.clearCachedProvider();
    const provider = await web3Modal.connect();
    
    // const ethersProvider = new ethers.providers.Web3Provider(provider, "ropsten");
    // window.web3 = ethersProvider;
    window.web3 = provider;
    
    
    

    // setProvider(ethersProvider);
    setProvider(provider)
    
    
    //const signer = await ethersProvider.getSigner();
    //let account = await signer.getAddress();
    let account = await getCurrentWalletAddress(provider);
    setAccount(account);
    
    ///these started producing an endless loop...
    // provider.on("chainChanged", () => {
    //   window.location.reload();
    // });

    // provider.on("accountsChanged", () => {
    //   window.location.reload();
    // });
  }, []);

  

  useEffect(() => {
    // const getWallet = () =>{
    //   if (web3Modal.cachedProvider) {
    //    // provider = await web3Modal.connect();
    //     await connectWeb3();
    //   } else {
    //     return null;
    //   }
    // }
    if(web3Modal.cachedProvider){
      connectWeb3();
    }
    

  }, [connectWeb3]);

  return (
    <Web3Context.Provider
      
      value={{
        ...state,
        connectWeb3,
        logout,
        // connected,
        mintNFT,
        //checkNet
        getTxnReceipt
      }}
    >
      {children}
    </Web3Context.Provider>
  );
};

async function getCurrentWalletAddress(provider) {
  try {
    // if (web3Modal.cachedProvider) {
    //   provider = await web3Modal.connect();
    // } else {
    //   return null;
    // }

    // let provider;
    // if(state.provider){
    //   provider = state.provider;
    // } else{
    //   provider = await web3Modal.connect();
    // }
    // Get a Web3 instance for the wallet
    const web3 = new Web3(provider);
    const accounts = await web3.eth.getAccounts();
    if (accounts && accounts.length > 0) {
      //selectedAccount = accounts[0];
      return accounts[0];
    } else {
      return null;
    }
  } catch (e) {
    console.error('Could not getCurrentWalletAddress', e);
    return null;
  }
}



