import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';


import CollectionItem from '../components/Collection/CollectionItem.js';

import PurchaseLayout from '../components/Layout/PurchaseLayout.js';
import '../css/PurchasePage.scss';


const PurchasePage = () => {
  //const lgApiUrl = process.env.REACT_APP_LG_API_URL;
  const [masterpiece, setMasterpiece] = useState();
  const [openSeaUrl, setOpenSeaUrl] = useState();
  const [etherscanUrl, setEtherscanUrl] = useState();

  const { tokenId, txnId } = useParams();
  //const lugApiUrl = process.env.REACT_APP_LG_API_URL;
  const env = process.env.REACT_APP_APPLICATION_ENV;
  const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS;

  
  const OsUrl = env === 'prod' ? `https://opensea.io/assets/${contractAddress}` : `https://testnets.opensea.io/assets/${contractAddress}`;
  const imageUrl = 'https://testsite.104zero.com';

  const loadJson = () => {
    const osUrl =
      env === 'prod' ? 'https://opensea.io' : 'https://testnets.opensea.io';
    setOpenSeaUrl(`${osUrl}/assets/${contractAddress}/${tokenId}`);

    const eUrl =
      env === 'prod' ? 'https://etherscan.io' : 'https://rinkeby.etherscan.io';
    setEtherscanUrl(`${eUrl}/tx/${txnId}`);

    setMasterpiece({id:{tokenId: tokenId}, contract:{address: contractAddress.toLocaleLowerCase()}});
    console.log(JSON.stringify(masterpiece))
    // const masterpiece = {id:{tokenId: tokenId}, contract:{address: contractAddress}};
    // masterpiece.id.tokenId = tokenId;
    // masterpiece.contract.address = contractAddress;
    

    // fetch(`${lugApiUrl}/transaction/${txnId}`)
    //   .then((response) => {
    //     // https://datatracker.ietf.org/doc/html/rfc4918#section-11.4
    //     if (response.status === 424) {
    //       setTimeout(() => {
    //         window.location.reload();
    //       }, 3000);
    //     } else {
    //       return response.json();
    //     }
    //   })
    //   .then((txn) => {
    //     if (txn) {
    //       setGoat(txn);
    //     }
    //   })
    //   .catch((e) => {
    //     console.error(e);
    //   });
  };

  useEffect(() => {
    //log here
    // fetch(`${lgApiUrl}/log/Goat confirmation page`, {
    //   method: 'POST',
    //   headers: { 'Content-Type': 'application/json' },
    //   body: JSON.stringify({
    //     tokenId,
    //     txnId,
    //   }),
    // }).catch((err) => {
    //   console.error({ err });
    // });

    //gtmInitialize();
    loadJson();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PurchaseLayout
      className='purchase-page'
      title='RAMbrandt - Purchase Successful'
    >
      <div className='container'>
        <div className='purchase-dialog'>
          <h4>Congratulations on your new RAMbrandt Masterpiece!</h4>
          <div className="img-section">
            {masterpiece ? (   
              <CollectionItem masterpiece={masterpiece} osUrl={OsUrl} imageUrl={imageUrl}/>
            ) : (
              // <img src={`${imageUrl}/numbers/${tokenId}`} alt ="masterpiece" />
              <img src="/load-loading.gif" alt="masterpiece loading" />
            )}
            {/* <img src = {`/NFTs/images/${tokenId}.png`} alt="masterpiece" /> */}
          </div>
          <div className="info-section">
            <div className="info-container">
              <h3>Congratulations on your masterpiece!</h3>
             
              <p>View on:</p>
              <a
                className="btn btn-opensea"
                href={openSeaUrl}
                target="_blank"
                rel="noreferrer"
              >
                {/* <img src="/images/Goat/opensea.png" alt="opensea" /> */}
                OpenSea
              </a>
              <a
                className="btn btn-etherscan"
                href={etherscanUrl}
                target="_blank"
                rel="noreferrer"
              >
                {/* <img src="/images/Goat/etherscan.png" alt="etherscan" /> */}
                Etherscan
              </a>
            </div>
          </div>
        </div>
        <h5>Enter your email for Rambrandt updates:</h5>
        {/* <EmailButton /> */}
      </div>
    </PurchaseLayout>
  );
};

export default PurchasePage;
