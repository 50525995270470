import React, {useEffect, useState, useContext, useRef} from 'react';
import {useParams, useHistory} from 'react-router-dom';
// import reactDom from 'react-dom';

import { Web3Context } from '../web3';

import PurchaseLayout from '../components/Layout/PurchaseLayout';
import Spinner from '../components/Spinner/Spinner';

import '../css/PurchaseLoading.scss';

const API_ADDRESS = process.env.REACT_APP_API_ADDRESS;
const API_VERIFY_HASH = process.env.REACT_APP_API_VERIFY_HASH;



const PurchaseLoading = () => {
    
    const navigate = useHistory();
    const {txnId, uuid} = useParams();
    const { getTxnReceipt, account } = useContext(Web3Context);

    const [moneyRaised, setMoneyRaised] = useState(0);
    const [fetchTimeout, setFetchTimeout] = useState();
    const hasFetchedInitial = useRef(false);

    const fetchTxnData = async () =>{  //useCallback(async () =>
        
        //reactDom.unmountComponentAtNode(document.getElementById("headerContainer"));
        console.log('fetching txn data')
        const txnReceipt = await getTxnReceipt(txnId);
        //console.log(`txnReceipt is: ${JSON.stringify(txnReceipt)} in fetchTxnData`)
        //if txn fails, return to error page
        //console.log('status is')
        //console.log(`status is: ${txnReceipt.status}`)
        if(txnReceipt && !txnReceipt.status) {

            //TODO: future add some sort of logging (via fetch/post api)
            
            clearTimeout(fetchTimeout);
            navigate.push(`/txn-error/${txnId}`)
            return false;

        }
        //console.log(JSON.stringify(txnReceipt))
        // console.log("trying")
        // console.log(parseInt(txnReceipt.logs[0].topics[3]))
        // const test = txnReceipt.logs[0].topics[3]
        // console.log(typeof parseInt(test));
        // console.log('done')

        const tokenId = (txnReceipt && txnReceipt.logs[0]  && txnReceipt.logs[0].topics
            ? parseInt(txnReceipt.logs[0].topics[3]) : null);

        //if the txn still is not complete, no tokenId
        //will this endless loop?
        if(tokenId == null){
            //TODO: add logging for transaction process post?
            return setFetchTimeout(setTimeout(fetchTxnData, 6000));
        }

        //with a good tokenId and complete status
        if(tokenId && tokenId > 0 && txnReceipt.status) {
            //TODO: add logging and transaction update for provenance
            //TODO: send data to link the uuid received (->URI)  to the correct NFT

            clearTimeout(fetchTimeout);
            //console.log('Verifycode: ' + API_VERIFY_HASH)
            console.log(`fetching: ${JSON.stringify({uuid: uuid, tokenID: tokenId, address: txnReceipt.from, txnHash: txnReceipt.transactionHash})}`)
            // const myHeaders = new Headers();
            // myHeaders.set('Content-Type', 'application/json');
            // myHeaders.set('verify-hash', API_VERIFY_HASH);
            // myHeaders.set('Host', 'api.104zero.com');
            // myHeaders.set('Access-Control-Allow-Origin', '*')
            // console.log(myHeaders.get('Host'));
            await fetch(
                `${API_ADDRESS}/numbers/`, 
                { 
                  mode: 'cors',               
                  method: 'POST', 
                  headers: {'Content-Type':'application/json',
                            'verify-hash': API_VERIFY_HASH},
                 // body: `uuid=${uuid}&token_id=${tokenId}`,
                    body: JSON.stringify({uuid: uuid, tokenID: tokenId, address: txnReceipt.from, txnHash: txnReceipt.transactionHash})
                }
            ).catch((err) => {
                console.log('logging error: ')
                console.error({ err });
            });  
            //console.log('response: ')
            //console.log(temp);    
            
            // const temp2 = await fetch(
            //     `${API_ADDRESS}`,
            //     {
            //         mode: 'no-cors',               
            //       method: 'PUT',
            //       headers: { 'Content-Type': 'application/x-www-form-urlencoded',
            //                     'verify-hash': API_VERIFY_HASH },
            //       body: `uuid=${uuid}&token_id=${tokenId}`,
            //     }
            // ).catch((err) => {
            //     console.log('logging error: ')
            //     console.error({ err });
            // });              
            console.log('response: ')
            //console.log(temp2); 
            const sleep = (ms) => {
                return new Promise(resolve => setTimeout(resolve, ms));
            }
            await sleep(1000);

            navigate.push(`/numbers/${tokenId}/txn/${txnReceipt.transactionHash}`);

        } else{  // the transaction has failed
            //TODO: add some logging for failed txn
            console.log('txn failed!')
            console.log(`for account: ${account}`)
            navigate.push(`/txn-error/${txnId}`)
            clearTimeout(fetchTimeout);
        }

    }//,[account, fetchTimeout, getTxnReceipt, navigate, txnId, uuid]);

    const fetchDonations = async () =>{
        //TODO: fetch number minted from API

        setMoneyRaised(Math.random()*100);

    }

    useEffect( () => { 
        //async () =>{
        //    await fetchDonations();
        //};
        if(!hasFetchedInitial.current){
            fetchDonations();
            setFetchTimeout(setTimeout(fetchTxnData, 6000));
            hasFetchedInitial.current = true;
            console.log('running in')
        }
        // console.log('running out')

    }, [fetchTxnData]);



    return (
        <PurchaseLayout
            className="purchase-loading"
            title="RAMbrandt - Creating Masterpiece"
            >
            `<div className="container">
                <div className="purchase-box">
                <h2>Your Rambrandt Masterpiece is being crafted!</h2>
                
                <Spinner />

                <h3>
                    Your NFT is being minted and this page will refresh automatically
                    <br />
                    <br />
                    Did you know that by purchasing this artwork
                    you are contributing to education around the world?  
                    Here is the impact RAMbrandt has already made!
                </h3>

                <div className="compute-result">
                    <div className="compute-item">
                    <h4>
                        <span>{moneyRaised}</span>
                    </h4>
                    Dollars Raised for:
                    **Charity**
                    </div>
                </div>
                </div>
            </div>
        </PurchaseLayout>
    )

}

export default PurchaseLoading;