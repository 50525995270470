import { useEffect } from 'react';
import ReactHelmet from 'react-helmet';
import MainNavbar from '../Navbars/MainNavbar';
import Footer from '../Footer/Footer';



const PurchaseLayout = ({ className, title, children }) => {
  useEffect(() => {
    if (className) {
      document.body.classList.add(className);
    }

    return () => {
      if (className) {
        document.body.classList.remove(className);
      }
    };
  }, []); //eslint-disable-line

  return (
    <>
      <ReactHelmet>
        <title>{title}</title>
      </ReactHelmet> 
      <MainNavbar />
      <main>{children}</main>
      
      {/* <Footer /> */}
    </>
  );
};

export default PurchaseLayout;
