export const Web3Reducer = (state, action) => {
  switch (action.type) {
    case "SET_ACCOUNT":
      return {
        ...state,
        account: action.payload,
        connected: (action.payload == null ? false : true)
      };
    case "SET_PROVIDER":
      return {
        ...state,
        provider: action.payload,
        
      };
    default:
      return state;
  }
};
