// import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import PurchaseLayout from '../components/Layout/PurchaseLayout';
//import { getTransactionReceipt } from 'src/utils/wallet';
//import { gtmInitialize } from 'src/utils/gtm';
import '../css/TxnFail.scss';

const TxnFail = () => {
  const { txnId } = useParams();
  const history = useHistory();
  const etherscanUrl = `${process.env.REACT_APP_ETHERSCAN_URL}/tx/${txnId}`;

  // const fetchAPIData = async () => {
  //   const txnRcpt = await getTransactionReceipt(txnId);
  //   console.log({ txnRcpt });
  // };

  const goHomepage = () => {
    history.push('/');
  };

  // useEffect(() => {
  //   //gtmInitialize();
  //   fetchAPIData();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return (
    <PurchaseLayout
      className="transaction-error"
      title="RAMbrandt - Transaction Error"
    >
      <div className="container error-container">
        <div className="error-dialog">
          <h2>Sorry, we ran into an issue...</h2>
          <span>
            Your transaction was reverted and cannot be processed. Please check
            etherscan for more details by clicking the button below{' '}
          </span>
          <a
            className="btn btn-etherscan"
            href={etherscanUrl}
            target="_blank"
            rel="noreferrer"
          >
            View transaction on Etherscan
          </a>
          <button className="btn btn-blue" onClick={goHomepage}>
            Go back to homepage
          </button>
        </div>
      </div>
    </PurchaseLayout>
  );
};

export default TxnFail;
